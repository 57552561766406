<template>
  <div class="card card-custom" v-loading="loading">
    <div class="card-body p-0">
       <!--begin: Settings-->
       <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
          <!--begin: Wizard Body-->
          <div class="row justify-content-center">
             <div class="col-xl-12">
                <!--begin: Wizard Form-->
                <el-form ref="form" :model="form" auto-complete="nope">
                   <!--begin: Wizard Step 1-->
                   <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                      <h3 class="mb-10 font-weight-bold text-dark"><i class="flaticon2-user"></i> Update Supplier Price</h3>                      
                      <div class="form-group">
                        <div class="col-sm-6">
                          <div tabindex="0" class="el-upload el-upload--text">
                            <button type="button" class="el-button el-button--primary el-button--small btn-button-upload">
                              <span>Select File</span>
                            </button>
                            <input type="file" name="file" accept=".csv" class="upload-btn" @change="loadCSV($event)">
                          </div>                          
                            <!-- <input type="file" id="csv_file" name="csv_file" class="form-control" @change="loadCSV($event)" > -->
                          <span class="el-upload__tip" slot="tip"><span class="text-red">*</span>Accept only .csv file</span>
                        </div>                        
                         <div class="col-sm-9 mt-5">
                           <span class="form-err" v-if="form.errors.has('product')" >Upload Product Supplier Price Change.csv file is required</span>                       
                           <span class="download_sample">                            
                            <download-csv                             
                              :data   = "json_data"
                              name    = "supplier_price_change.csv">
                              <i class="flaticon-attachment icon-md"></i>                          
                              Download Sample CSV                           
                          </download-csv>
                            </span>
                         </div>
                      </div>
                      <div class="d-flex align-items-center flex-wrap mt-8 col-sm-12" v-loading="loading">
                        <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2" v-for="(web,index) in import_data" :key="index">
                           <span class="mr-4"><i class="flaticon-upload display-4 text-muted font-weight-bold"></i></span>
                          <div class="d-flex flex-column text-dark-75 "> <span class="font-weight-bolder font-size-h5">{{web.name}}</span> 
                          <div class="font-weight-bolder font-size-md">                          
                            <span class="font-weight-bold active font-size-h6" @click="successDocument(web.prefix)" style="cursor:pointer;"><i class="flaticon-download icon-md"></i></span> <span class="font-weight-bold active font-size-md mr-5"> Success : {{(web.result.success).length}}</span>
                            <span class="font-weight-bold   font-size-h6" style="cursor:pointer;" @click="errorDocument(web.prefix)" v-if="(web.result.error).length>0"><i class="flaticon-download icon-md"></i></span> <span class="font-weight-bold red font-size-md "> Fail : {{(web.result.error).length}}</span>
                          </div>                          
                          </div>
                        </div>                       
                      </div>
                                        
                      <!-- <div class="col-sm-offset-3 col-sm-9">
                        <a href="#" class="btn btn-primary">Parse CSV</a>
                      </div> -->
                        <div class="mt-4 text-white mb-5">
	                    	<div class="col-sm-12">
	                    		<el-button size="small" type="success" @click="uploadProduct"  :loading="loading">Update Price</el-button>
	                    	</div>
	                    </div>
                      <!-- <div class="col-sm-offset-3 col-sm-9">
                        <div class="checkbox-inline">
                            <label for="header_rows"><input type="checkbox" id="header_rows" checked> File contains header row?</label>
                        </div>
                      </div> -->
                      <div class="col-sm-12" >
                        <table v-if="parse_csv">
                          <thead>
                              <tr>
                                <th v-for="(key,k) in parse_header" :key="k"
                                    @click="sortBy(key)"
                                    :class="{ active: sortKey == key }">
                                    {{ key | capitalize }}
                                    <span class="arrow" :class="sortOrders[key] > 0 ? 'asc' : 'dsc'">
                                    </span>
                                </th>
                              </tr>
                          </thead>
                          <tr v-for="(csv,c) in parse_csv" :key="c">
                              <td v-for="(key,j) in parse_header" :key="j">
                                {{csv[key]}}
                              </td>
                          </tr>
                        </table>
                      </div>                      
                  </div>
                  </el-form>
              </div>
           </div>
        </div>
     </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from '@/core/services/api.form.services';
import {downloadSuccessImport,downloadErrorImport} from "@/api/product";
//import {getAllActiveSites} from "@/api/website";
export default {
    data() {
    return {
      api:'supplier/product/import',
      loading:false,
      channel_name: '',
      channel_fields: [],
      channel_entries: [],
      parse_header: [],
      parse_csv: [],
      sortOrders:{},
      sortKey: '',
      	form: new Form({
				product:null
			}), 
      import_data:[],
      success_import:null,
      error_import:null,
      websites : [],
      json_data: [
            {   
                'SKU': 'SBG1311',
                'Supplier': 'HD',
                'Supplier SKU' : 'DF4555',      
                'MFG ID': '1000113424', 
                'UPC Code' : '567845678945',               
                'Cost Price': '43.98',
                'Description': 'Plywood Good One Side 1/4 X 4 X 8',                              
            },
            {   
                'SKU': 'SBG1422',
                'Supplier': 'Watson',      
                'Supplier SKU' : 'DRFG4555',     
                'MFG ID': '1000113424',
                'UPC Code' : '567845678945',                 
                'Cost Price': '4.98',
                'Description': 'Slotwall Paint Grade 3/4 x 4 x 8',                              
            },
        ]
      
    };
  },
  filters: {
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Product Management", route: "/product/list" },
      { title: "Import Product" }
    ]);
  },
  methods: {
    sortBy: function (key) {
      var vm = this
      vm.sortKey = key
      vm.sortOrders[key] = vm.sortOrders[key] * -1
    },
    csvJSON(csv){
      var vm = this
      var lines = csv.split("\n")
      var result = []
      var headers = lines[0].split(",")
      vm.parse_header = lines[0].split(",") 
      lines[0].split(",").forEach(function (key) {
        vm.sortOrders[key] = 1
      })
      
      lines.map(function(line, indexLine){
        if (indexLine < 1) return // Jump header line
        
        var obj = {}
        var currentline = line.split(",")
        
        headers.map(function(header, indexHeader){
          obj[header] = currentline[indexHeader]
        })
        
        result.push(obj)
      })
      
      result.pop() // remove the last item because undefined values
      
      return result // JavaScript object
    },
    loadCSV(e) {
      var vm = this      
      if (window.FileReader) {
        var reader = new FileReader();
        reader.readAsText(e.target.files[0]);
        this.form.product = e.target.files[0]; 
        // Handle errors load
        reader.onload = function(event) {
          var csv = event.target.result;
          vm.parse_csv = vm.csvJSON(csv)
          
        };
        reader.onerror = function(evt) {
          if(evt.target.error.name == "NotReadableError") {
            alert("Canno't read file !");
          }
        };
      } else {
        alert('FileReader are not supported in this browser.');
      }
    },
    uploadProduct(){
       	  this.loading = true;
	    		this.form.post(this.api).then((response) => {          
		        if(response.status){           
              this.import_data = response.data;
              this.loading=false;  
              this.$showResponse("success", response.message);  
              this.parse_csv = [];         
		        }		      
		      }).catch(() => {
                this.loading = false;
            });
    },

    successDocument(prefix){
     downloadSuccessImport(prefix).then(response => {         
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'success import.csv');
          document.body.appendChild(link);
          link.click();
          //this.loading = false;
        }).catch(err => {
          console.log(err);
          this.loading = false;
        });
  },
  errorDocument(prefix){
     downloadErrorImport(prefix).then(response => {         
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'error import.csv');
          document.body.appendChild(link);
          link.click();
          //this.loading = false;
        }).catch(err => {
          console.log(err);
          this.loading = false;
        });
  }

  },
  
}
</script>

<style scoped>
html, body {
  margin: 0;
  padding: 0;
}
body {
  margin: 32px auto;
}
.panel {
  border: 2px solid #dfdfdf;
  box-shadow: rgba(0, 0, 0, 0.15) 0 1px 0 0;
  margin: 10px;
} 
.panel.panel-sm {
  max-width: 700px;
  margin: 10px auto;
}
.panel-heading {
  border-bottom: 2px solid #dfdfdf;
}
.panel-heading h1, .panel-heading h2, .panel-heading h3, .panel-heading h4, .panel-heading h5, .panel-heading h6 {
  margin: 0;
  padding: 0;
}
.panel-body .checkbox-inline {
  padding: 15px 20px;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
.icon-md{
  color:#3699ff !important
}
.download_sample{
    color: #3699ff;
    cursor: pointer;
}
</style>
